import * as React from "react";
import { Link } from "gatsby";
import PatientLayout from "../components/patient/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../components/seo";

const SiteMapPage = () => {
  return (
    <PatientLayout pageClass="patient-contact-us">
      <section className="btm-spacer">
        <Row>
          <Col xs={12}>
            <h1 className="first interior-first">Site Map</h1>
            <ul className="bullet-list pink">
              <li><Link to="/savings-program/">Savings Program</Link></li>
              <li><Link to="/resources/">Resources</Link></li>
              <li><Link to="/safety/">Indication & Important Safety Information</Link></li>
            </ul>
          </Col>
        </Row>
      </section>
    </PatientLayout>
  );
};

export default SiteMapPage;

export const Head = () => <Seo title="Site Map" description="Find the Hysingla ER Extended-Release Tablets CII patient site map. See Full PI, Medication Guide & Boxed Warning." />;
